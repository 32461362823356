import { SearchModel } from '../types/SearchModel';
import { serializeFilter } from './serializeFilter';
import { setSearchCache } from './setSearchCache';
import { updateSearchCacheEntry } from './updateSearchCacheEntry';

export function initSearchCacheEntry<TFilter, TData>(
  filter: TFilter | undefined,
  cache: { [key: string]: SearchModel<TData> },
): ReturnType<typeof setSearchCache<TData>> | null;
export function initSearchCacheEntry<TFilter, TData>(
  filter: TFilter | undefined,
  cache: { [key: string]: SearchModel<TData> },
  reset: true,
): ReturnType<typeof setSearchCache<TData>>;
export function initSearchCacheEntry<TFilter, TData>(
  filter: TFilter | undefined,
  cache: { [key: string]: SearchModel<TData> },
  reset: false,
): ReturnType<typeof setSearchCache<TData>> | null;
export function initSearchCacheEntry<TFilter, TData>(
  filter: TFilter | undefined,
  cache: { [key: string]: SearchModel<TData> },
  reset = false,
) {
  const key = serializeFilter(filter);
  if (key in cache && !reset) {
    return null;
  }

  const search = {
    key,
    created: 0,
    updated: 0,
    totalResults: -1,
    results: [],
  };

  return updateSearchCacheEntry(search, cache);
}
