import { SearchModel } from '../types/SearchModel';
import { updateSearchCacheEntry } from './updateSearchCacheEntry';

export function updateSearchError<TData>(error: any, searchKey: string, cache: { [key: string]: SearchModel<TData> }) {
  return cache[searchKey]
    ? updateSearchCacheEntry(
        {
          ...cache[searchKey],
          error,
        },
        cache,
      )
    : { _searchCache: cache };
}
