import { computed, untracked } from '@angular/core';
import { patchState, signalStoreFeature, withComputed, withMethods, withState } from '@ngrx/signals';
import { tap } from 'rxjs';
import { RequestStatus, withRequest } from '../request-status';
import { initSearchCacheEntry } from './operations/initSearchCacheEntry';
import { serializeFilter } from './operations/serializeFilter';
import { setFilterSlice } from './operations/setFilterSlice';
import { updateSearchCacheEntry } from './operations/updateSearchCacheEntry';
import { updateSearchError } from './operations/updateSearchError';
import { defaultWithSearchResultsConfig, LoadSearchResultsFactory, SearchModel, SearchState, WithSearchResultsConfig } from './types';

export function withSearchResults<TFilter, TData>(
  initialSearchFilter: TFilter,
  loadFn: LoadSearchResultsFactory<TFilter, TData>,
  config?: Partial<WithSearchResultsConfig>,
) {
  const cfg: WithSearchResultsConfig = { ...defaultWithSearchResultsConfig, ...config };

  return signalStoreFeature(
    withState<SearchState<TFilter, TData>>({
      _searchCache: {},
      searchFilter: initialSearchFilter,
    }),
    withComputed((store) => {
      const searchFilterSerialized = computed(() => serializeFilter(store.searchFilter() ?? ({} as TFilter)));

      const search = computed(() => {
        const key = searchFilterSerialized();
        return store._searchCache()[key];
      });

      return {
        searchFilterSerialized,
        search,
      };
    }),
    withRequest('_loadAndAppendSearchResults', (store) => {
      const loader = loadFn();
      return (count: number, emit) => {
        const filter = untracked(store.searchFilter);
        const key = untracked(store.searchFilterSerialized);
        const search = untracked(store.search);

        return loader(filter, search.results.length, count).pipe(
          tap({
            next: (response) => {
              const search = untracked(store.search)!;
              const cache = untracked(store._searchCache);

              const results = [...search.results.filter((r) => !!r)] as TData[];
              (response.data ?? []).forEach((item) => {
                // if (!results.some((r) => r?.id === item.id)) {
                results.push(item);
                // }
              });

              const now = Date.now();

              const newSearch: SearchModel<TData> = {
                key,
                created: search.created || now,
                updated: now,
                totalResults: response.total,
                results,
              };
              patchState(store, updateSearchCacheEntry(newSearch, cache));
            },
            error: (error) => {
              patchState(store, updateSearchError(error, key, store._searchCache()));
            },
          }),
        );
      };
    }),
    withMethods((store) => {
      const setSearchFilter = (filter: TFilter | undefined) => {
        const init = initSearchCacheEntry<TFilter, TData>(filter, store._searchCache());
        if (init) {
          patchState(store, {
            ...init,
            ...setFilterSlice<TFilter>(filter),
          });
        } else {
          patchState(store, setFilterSlice(filter));
        }
      };

      const clearSearchFilter = () => {
        setSearchFilter(undefined);
      };

      const loadSearchResults = (count: number) => {
        if (store._loadAndAppendSearchResultsStatus() === RequestStatus.FETCHING) {
          return;
        }

        let search = store.search();
        if (!search) {
          setSearchFilter(undefined);
          search = store.search();
        }

        const now = Date.now();
        if (search.created + cfg.maxCacheAge < now) {
          const searchCache = initSearchCacheEntry(store.searchFilter(), store._searchCache(), true);
          patchState(store, searchCache);
          store._loadAndAppendSearchResults(count);
          searchCache._searchCache[search.key].results = new Array(count).fill(null);
          patchState(store, searchCache);
        } else if (search.results.length < count) {
          const delta = count - search.results.length;
          store._loadAndAppendSearchResults(delta);
        }
      };

      const loadMoreSearchResults = (count: number) => {
        if (store._loadAndAppendSearchResultsStatus() === RequestStatus.FETCHING) {
          return;
        }
        const search = store.search();
        const currResultCount = search.results.length;
        const loadCount = Math.min(count, search.totalResults - currResultCount);
        if (loadCount < 1) {
          return;
        }
        const newSearch = {
          ...search,
          results: [...search.results, ...new Array(loadCount).fill(null)],
        };
        store._loadAndAppendSearchResults(count);
        patchState(store, updateSearchCacheEntry(newSearch, store._searchCache()));
      };

      return {
        setSearchFilter,
        clearSearchFilter,
        loadSearchResults,
        loadMoreSearchResults,
      };
    }),
    withComputed((store) => {
      const searchStatus = computed(() => store._loadAndAppendSearchResultsStatus());
      const searchError = computed(() => store.search().error);
      return {
        searchStatus,
        searchError,
      };
    }),
  );
}
