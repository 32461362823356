import { inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import { patchState, SignalStoreFeature, signalStoreFeature, StateSignal, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import type { EmptyFeatureResult, SignalStoreFeatureResult, SignalStoreSlices } from '@ngrx/signals/src/signal-store-models';
import type { Prettify } from '@ngrx/signals/src/ts-helpers';
import { minimumTime } from 'common-util';
import { filter, merge, Observable, pipe, share, Subject, Subscription, switchMap, tap } from 'rxjs';
import { RequestEvent } from './RequestEvent';
import { RequestMethod } from './RequestMethod';
import { RequestOptions } from './RequestOptions';
import { RequestStatus } from './RequestStatus';
import { RequestStatusEvent } from './RequestStatusEvent';
import { DefaultRequestOptions } from './tokens/DefaultRequestOptions';

declare type Mutable<T extends object> = {
  -readonly [K in keyof T]: T[K];
};

type RequestPropertyName<NAME extends string> = `${NAME}`;
type RequestStatusPropertyName<NAME extends string> = `${NAME}Status`;

export function withRequest<Input extends SignalStoreFeatureResult, TResult, TName extends string, TArgs = void>(
  name: TName,
  requestFactory: (
    store: Prettify<SignalStoreSlices<Input['state']> & Input['signals'] & Input['methods'] & StateSignal<Prettify<Input['state']>>>,
  ) => (args: TArgs, eventEmitter: Subject<RequestEvent>) => Observable<TResult>,
  options: Partial<RequestOptions> | (() => Partial<RequestOptions>) = {},
): SignalStoreFeature<
  Input,
  EmptyFeatureResult & {
    methods: {
      [K in TName]: RequestMethod<TArgs>;
    };
    state: {
      [K in RequestStatusPropertyName<TName>]: RequestStatus;
    };
  }
> {
  return signalStoreFeature(
    withState({
      [`${name}Status`]: RequestStatus.INITIALIZING,
    }) as SignalStoreFeature<
      EmptyFeatureResult,
      EmptyFeatureResult & {
        state: {
          [K in `${TName}Status`]: RequestStatus;
        };
      }
    >,
    withMethods((store) => {
      const opts: RequestOptions = {
        ...inject(DefaultRequestOptions),
        ...inject(RequestOptions),
        ...(typeof options === 'function' ? options() : options),
      };

      const request = requestFactory(store as any);

      const status = signal(RequestStatus.INITIALIZING);
      const error = signal(null);

      const evtEmit = new Subject<RequestEvent>();
      const statusEvents = evtEmit.pipe(
        filter((v): v is RequestStatusEvent => v instanceof RequestStatusEvent),
        minimumTime(opts.minimumStatusChangeTime, (n, o) => !o || o.status === RequestStatus.INITIALIZING),
        tap((v) => {
          patchState(store, {
            [`${name}Status`]: v.status,
          } as { [K in `${TName}Status`]: RequestStatus });
          status.set(v.status);
          error.set(v.error);
        }),
        share(),
      );
      const nonStatusEvents = evtEmit.pipe(filter((v) => !(v instanceof RequestStatusEvent)));
      const events = merge(statusEvents, nonStatusEvents).pipe(takeUntilDestroyed(), share());

      const requestMethod: Mutable<RequestMethod<TArgs>> = rxMethod<TArgs>(
        pipe(
          switchMap((args: TArgs) => {
            evtEmit.next(new RequestStatusEvent(RequestStatus.FETCHING, null));
            return request(args, evtEmit).pipe(
              tapResponse({
                next: () => {
                  evtEmit.next(new RequestStatusEvent(RequestStatus.READY, null));
                },
                error: (error) => {
                  evtEmit.next(new RequestStatusEvent(RequestStatus.ERROR, error));
                },
              }),
            );
          }),
        ),
      ) as RequestMethod<TArgs>;

      requestMethod.events = events;
      requestMethod.status = status;
      requestMethod.error = error;

      return {
        [name]: requestMethod as RequestMethod<TArgs>,
      } as {
        [K in TName]: RequestMethod<TArgs>;
      };
    }),
    withHooks((store) => {
      let sub: Subscription;
      return {
        onInit() {
          sub = store[name].events.subscribe();
        },
        onDestroy() {
          sub?.unsubscribe();
        },
      };
    }),
  ) as any;
}
